import { TicketshopPage } from "components/TicketshopPage";
import React from "react";

export default function JALPage() {
  return (
    <TicketshopPage
      scriptString={`<script data-name-filter="little" src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-fixr-shop-id="f8e48caf-038f-4062-b6b5-e963e15a7183" data-fixr-theme="light"></script>`}
      imageSrc="/logo-jal.png"
      backgroundSrc="/just-a-little-hero.jpg"
      imageMaxWidth="562px"
      title="JUST A LITTLE"
      description={`Focusing on the sunshine sounds of house & garage, Just A Little is set in one of the sickest underground venues in the city. Move your feet to wavy house sounds, deep garage basslines and UK funky flavas all set under bright lights, shimmering disco balls & low ceilings. This one is an intimate affair where you can hear your favourite jams and discover new music.`}
    />
  );
}
